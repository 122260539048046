<template>
  <div class="add_teacher">
    <el-page-header @back="$router.go(-1)" content="添加/修改"> </el-page-header>
    <br />

    <el-form ref="addFromRef" :model="addFrom" :rules="addFromRules" label-width="110px">
      <el-tabs type="border-card">
        <el-tab-pane label="基本信息">
          <el-form-item label="标题:" prop="title">
            <el-input v-model="addFrom.title" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="二级标题:" prop="secondTitle">
            <el-input v-model="addFrom.secondTitle" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="分享标题:" prop="shareTitle">
            <el-input v-model="addFrom.shareTitle" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="标签:" prop="label">
            <el-tag
              style="margin-right: 5px"
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              style="width: 80px"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput"
              >+ 添加</el-button
            >
          </el-form-item>

          <el-form-item label="所属分类:" prop="cateId">
            <el-select
              v-model="fatherId"
              @change="getFartherCate(fatherId, 2)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateList"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              ></el-option>
            </el-select>
            <el-select v-if="fatherId" v-model="addFrom.cateId" placeholder="请选择">
              <el-option
                v-for="item in cateListSun"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属景区:" prop="sceneryIds">
            <el-select v-model="addFrom.sceneryIds" placeholder="请选择">
              <el-option
                v-for="item in sceneryOptions"
                :key="item.sceneryId"
                :label="item.sceneryName"
                :value="item.sceneryId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供应商" prop="supplierId">
            <el-select v-model="addFrom.supplierId" placeholder="请选择">
              <el-option
                v-for="item in supplierOptions"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="游戏ID:" prop="gameId">
            <el-input v-model="addFrom.gameId" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="是否显示:">
            <!-- 单选 -->

            <el-radio v-model="addFrom.isShow" :label="1">显示</el-radio>
            <el-radio v-model="addFrom.isShow" :label="0">隐藏</el-radio>
          </el-form-item>
          <el-form-item label="万旅网是否显示:">
            <!-- 单选 -->

            <el-radio v-model="addFrom.wlShow" :label="1">显示</el-radio>
            <el-radio v-model="addFrom.wlShow" :label="0">隐藏</el-radio>
          </el-form-item>
          <el-form-item label="是否享受会员权益:">
            <!-- 单选 -->

            <el-radio v-model="addFrom.isUser" :label="1">是</el-radio>
            <el-radio v-model="addFrom.isUser" :label="0">否</el-radio>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="展示图">
          <el-form-item label="展示图:" prop="showImg">
            <send-image
              type="one"
              :images="addFrom.showImg"
              @addImageStr="addImageStr"
            ></send-image>

            <span style="color: red; margin-left: 20px"
              >每张图片不能超过500K，建议尺寸：100*100px</span
            >
          </el-form-item>
          <el-form-item label="轮播图:" prop="imgUrl">
            <send-image
              type="more"
              :images="addFrom.imgUrl"
              @addImageStrs="addImageStrMore"
            ></send-image>

            <span style="color: red; margin-left: 20px"
              >每张图片不能超过500K，建议尺寸：750*500px</span
            >
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="详情">
          <el-form-item label="详情:" prop="details">
            <VueUeditorWrap
              v-model="addFrom.details"
              :config="myConfig"
              ref="myTextEditor"
            ></VueUeditorWrap>
          </el-form-item>
          <el-form-item label="退改说明:" prop="bookNotice">
            <VueUeditorWrap
              v-model="addFrom.bookNotice"
              :config="myConfig"
              ref="myTextEditor"
            ></VueUeditorWrap>
          </el-form-item>
        </el-tab-pane>

        <el-form-item>
          <el-button type="success" @click="submitForm">保存</el-button>
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-form-item>
      </el-tabs>
    </el-form>
  </div>
</template>
<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import editorConfig from "../../utils/editorConfig.js";
import SendImage from "../../components/sendImage.vue";
import {
  foodCateAllById,
  foodListAdd,
  foodListDetails,
  getScenery,
} from "../../api/food.js";
// import { allScenery } from "../../api/sceneryList";
import { supList } from "../../api/supList.js";
export default {
  components: { VueUeditorWrap, SendImage },
  data() {
    return {
      myConfig: editorConfig,
      fatherId: "",
      addFrom: {
        foodId: 0,
        title: "",
        secondTitle: "",
        cateId: "",
        label: "",
        gameId: "",
        showImg: "",
        imgUrl: "",
        details: "",
        sceneryIds: "",
        isShow: 1,
        isUser: 0,
        supplierId: "",
        bookNotice: "",
        shareTitle: "",
        wlShow: 1,
      },
      supplierOptions: [{ cateId: 0, cateName: "自营" }],
      cateList: [],
      sceneryOptions: [],
      cateListSun: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      addFromRules: {
        title: [{ required: true, message: "请输入标题", trigger: ["blur", "change"] }],
        secondTitle: [
          { required: true, message: "请输入副标题", trigger: ["blur", "change"] },
        ],
        cateId: [{ required: true, message: "请选择分类", trigger: ["blur", "change"] }],
        label: [{ required: true, message: "请输入标签", trigger: ["blur", "change"] }],
        gameId: [{ required: true, message: "请选择游戏", trigger: ["blur", "change"] }],
        showImg: [
          { required: true, message: "请上传展示图", trigger: ["blur", "change"] },
        ],
        imgUrl: [
          { required: true, message: "请上传详情图", trigger: ["blur", "change"] },
        ],
        details: [{ required: true, message: "请填写详情", trigger: ["blur", "change"] }],
        sceneryIds: [
          { required: true, message: "请选择景区", trigger: ["blur", "change"] },
        ],
        supplierId: [
          { required: true, message: "请选择供应商", trigger: ["blur", "change"] },
        ],
        bookNotice: [
          { required: true, message: "请填写退改说明", trigger: ["blur", "change"] },
        ],
        shareTitle: [
          { required: true, message: "请填写分享标题", trigger: ["blur", "change"] },
        ],
      },
    };
  },
  created() {
    this.$route.query.foodId && (this.addFrom.foodId = this.$route.query.foodId);
    if (this.addFrom.foodId) {
      this.getDetails();
    }
    this.getSupplierList();
    this.getFartherCate();
    this.getSceneryCateList();
  },
  methods: {
    async getSceneryCateList() {
      const { data } = await getScenery();
      this.sceneryOptions = data.data;
    },
    getSupplierList() {
      supList().then((res) => {
        this.supplierOptions.push(...res.data.data);
      });
    },
    getFartherCate(fatherId = 0, type = 1) {
      foodCateAllById({ fatherId }).then((res) => {
        if (res.data.code == 0) {
          if (type == 2) {
            this.cateListSun = res.data.data;
          } else {
            this.cateList = res.data.data;
          }

          //   this.fatherId = res.data.data[0].cateId;
        }
      });
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    addImageStrMore(e) {
      console.log(e, "more");
      this.addFrom.imgUrl = e.join(",");
    },
    getDetails() {
      foodListDetails({ foodId: this.addFrom.foodId }).then((res) => {
        if (res.data.code == 0) {
          //   this.addFrom = res.data.data;
          this.fatherId = res.data.data.firstCateId;
          this.addFrom.label = res.data.data.label.join(",");
          this.addFrom.details = res.data.data.details;
          this.addFrom.gameId = res.data.data.gameId;
          this.addFrom.showImg = res.data.data.showImg;
          this.addFrom.title = res.data.data.title;
          this.addFrom.secondTitle = res.data.data.secondTitle;
          this.addFrom.cateId = res.data.data.cateId;
          this.addFrom.imgUrl = res.data.data.imgUrl.join(",");
          this.addFrom.sceneryIds = Number(res.data.data.sceneryIds[0]);
          this.addFrom.isShow = Number(res.data.data.isShow);
          this.addFrom.isUser = res.data.data.isUser;
          this.addFrom.supplierId = res.data.data.supplierId;
          this.addFrom.bookNotice = res.data.data.bookNotice;
          this.addFrom.shareTitle = res.data.data.shareTitle;
          this.addFrom.wlShow = res.data.data.wlShow;

          this.dynamicTags = res.data.data.label;
          this.getFartherCate(this.fatherId, 2);
        }
      });
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.addFrom.label = this.dynamicTags.join(",");
      this.inputVisible = false;
      this.inputValue = "";
    },
    submitForm() {
      //   console.log(this.addFrom);
      //   return;
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) {
          return this.$message.warning("请完善信息");
        }
        if (this.isSubmit) return;
        this.isSubmit = true;

        const { data } = await foodListAdd(this.addFrom);
        this.isSubmit = false;
        if (data.code == 0) {
          this.goBack();
          this.$message.success(data.msg);
        } else {
          this.$message.warning(data.msg);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    addImageStr(e) {
      this.addFrom.showImg = e;
    },
  },
};
</script>

<style scoped>
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
}
.box-card {
  padding-bottom: 50px;
}
</style>
